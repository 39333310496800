@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');

:root {
  --background-color: #FCFCFC;  
}
.App {
  position: fixed;
  text-align: center;
  background-color: var(--background-color);
  height: 100%;
  width: 100%;
}

.content{
  text-align: left;
  padding: 2%;
  font-family: Arial, Helvetica, sans-serif;
  font-family: "Gilda Display", serif;
}

.App-header {
  font-family: "Gilda Display", serif;
  background-color: var(--background-color);
  height: 7.5vh;
  display: inline-flex;
  align-self: center;
  justify-content: left; /* Align items to the start (left) */
  align-items: center; /* Align items to the center */
  font-size: calc(10px + 2vmin);
  color: #141414;
  border-bottom: solid;


  /*
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  */
}

footer{
  font-family: "Gilda Display", serif;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #141414;
  color: var(--background-color);
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.App-logo {
  position: absolute;
  padding-top: 1vh;
  padding-right: 0.5vw;
  padding-bottom: 1vh;
  width: 25vw;
  max-width: 200px;
  left: 0;

}
