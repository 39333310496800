@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&family=Quicksand:wght@300..700&display=swap');

form {
    justify-content: center;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: #141414;
    font-family: "Gilda Display", serif;
    border-radius: 0%;
}

.Cascader {
    border-radius: 0%;
    font-family: "Gilda Display", serif;
    border-radius: 0%;
}

.submit {
    font-family: "Gilda Display", serif;
    background-color: #9C9C9C;
    color: #141414;
    border-radius: 0%;
    
}

.ant-select-selector {
    border-radius: 0%;
    background-color: #552020;
    color: #141414;
}