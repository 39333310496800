@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&family=Quicksand:wght@300..700&display=swap');

.container{
    display: flex;
    font-family: "Gilda Display", serif;
    font-size: 24px;
    margin: 25px;
    width: auto;
    height: auto;
}

.card{
    background-color: white;
    margin: 0 auto;
    outline: auto;
    font-family: "Gilda Display", serif;
    /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
    width: 250px;
}